<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="success"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="info"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="warning"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

            <el-alert
                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"
                type="error"
                effect="dark" style="margin-bottom: 20px" >
            </el-alert>
          </div>



          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>




        </div>



      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "Promotion",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/special/1000599.png','id':'20182','search':''},   //20182	1000599	swisse 血橙胶原蛋白饮料
        {'index':1,'url':'http://image.yabyy.com/home/special/1000726.png','id':'4593','search':''},   //4593	1000726	蛋白粉
        {'index':2,'url':'http://image.yabyy.com/home/special/1000728.png','id':'4595','search':''},   //4595	1000728	乐赛牌植物乳酸菌胶囊
        {'index':3,'url':'http://image.yabyy.com/home/special/1009540.png','id':'20183','search':''},   //20183	1009540	swisse 血橙饮料

        {'index':4,'url':'http://image.yabyy.com/home/special/1010814.png','id':'20184','search':''},     //20184	1010814	swisse 深海鱼油胶囊
        {'index':5,'url':'http://image.yabyy.com/home/special/1013137.png','id':'12648','search':''},      //12648	1013137	swisse钙维生素D片
        {'index':6,'url':'http://image.yabyy.com/home/special/1040896.png','id':'20281','search':''},      //20281	1040896	Swisse斯维诗牌乳清蛋白粉(香草味)
        {'index':7,'url':'http://image.yabyy.com/home/special/1040897.png','id':'20282','search':''},      //20282	1040897	Swisse孕产妇DHA鱼油软胶囊


      ],
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
